export const MARKETPLACE_ALIEXPRESS = "aliexpress";
export const MARKETPLACE_OZON = "ozon";
export const MARKETPLACE_WILDBERRIES = "wb";
export const MARKETPLACE_YANDEX_MARKET = "ym";
export const MARKETPLACE_SBER_MEGA_MARKET = "sm";
export const AVITO_CODE = "avito";
export const KAZAN_EXPRESS_CODE = "kazan_express";
export const LEROY_MERLIN_CODE = "leroy_merlin";
export const DETSKIY_MIR_CODE = "detskiy_mir";
export const LAMODA_CODE = "lamoda";

export const MARKETPLACE_LIST = [
  MARKETPLACE_ALIEXPRESS,
  MARKETPLACE_WILDBERRIES,
  MARKETPLACE_OZON,
  MARKETPLACE_YANDEX_MARKET,
  MARKETPLACE_SBER_MEGA_MARKET,
  AVITO_CODE,
  KAZAN_EXPRESS_CODE,
  LEROY_MERLIN_CODE,
  DETSKIY_MIR_CODE,
  LAMODA_CODE,
];

export const RELATION_UPLOAD_STATUS_NOT_UPLOADED = 0;
export const RELATION_UPLOAD_STATUS_UPLOADED = 1;
export const RELATION_UPLOAD_STATUS_ERROR = 2;

export const RELATION_STATUS_ON = 0;
export const RELATION_STATUS_OFF = 1;

export const FILTER_OPERATOR_EQ = 0;
export const FILTER_OPERATOR_ILIKE = 1;
export const FILTER_OPERATOR_NOT_EQ = 2;
export const FILTER_OPERATOR_MORE = 3;
export const FILTER_OPERATOR_MORE_EQ = 4;
export const FILTER_OPERATOR_LESS = 5;
export const FILTER_OPERATOR_LESS_EQ = 6;
export const FILTER_OPERATOR_IN = 7;
export const FILTER_OPERATOR_NOT_ILIKE = 8;

const MARKETPLACE_ID_LIST = {
  origin: 0,
  [MARKETPLACE_ALIEXPRESS]: 1,
  [MARKETPLACE_OZON]: 2,
  [MARKETPLACE_YANDEX_MARKET]: 3,
  [MARKETPLACE_WILDBERRIES]: 4,
  [MARKETPLACE_SBER_MEGA_MARKET]: 5,
  [AVITO_CODE]: 7,
  [KAZAN_EXPRESS_CODE]: 8,
  [LEROY_MERLIN_CODE]: 9,
  [DETSKIY_MIR_CODE]: 10,
  [LAMODA_CODE]: 11,
};

const MARKETPLACE_NAME_LIST = {
  origin: "All",
  [MARKETPLACE_ALIEXPRESS]: "Aliexpress",
  [MARKETPLACE_OZON]: "Ozon",
  [MARKETPLACE_YANDEX_MARKET]: "Yandex market",
  [MARKETPLACE_WILDBERRIES]: "Wildberries",
  [MARKETPLACE_SBER_MEGA_MARKET]: "МегаМаркет",
  [AVITO_CODE]: "Авито",
  [KAZAN_EXPRESS_CODE]: "Магнит маркет",
  [LEROY_MERLIN_CODE]: "Лемана Про (Леруа Мерлен)",
  [DETSKIY_MIR_CODE]: "Детский Мир",
  [LAMODA_CODE]: "Lamoda",
};

export const getMarketplacesAllForSelect = () => {
  const result = [];
  for (let el in MARKETPLACE_ID_LIST) {
    if (el === "origin") {
      continue;
    }
    result.push({
      id: MARKETPLACE_ID_LIST[el],
      code: el,
      title: MARKETPLACE_NAME_LIST[el],
    });
  }

  return result;
};

export const getMarketplaceId = (marketplace) => {
  // eslint-disable-next-line no-prototype-builtins
  if (!MARKETPLACE_ID_LIST.hasOwnProperty(marketplace)) {
    throw `Неизвестный маркетплейс: ${marketplace}!`;
  }

  return MARKETPLACE_ID_LIST[marketplace];
};

export const getMarketplaceName = (marketplace) => {
  // eslint-disable-next-line no-prototype-builtins
  if (!MARKETPLACE_NAME_LIST.hasOwnProperty(marketplace)) {
    throw "Неизвестный маркетплейс!";
  }

  return MARKETPLACE_NAME_LIST[marketplace];
};

export const getMarketplaceCodeById = (id) => {
  for (let code in MARKETPLACE_ID_LIST) {
    if (MARKETPLACE_ID_LIST[code] === id) {
      return code;
    }
  }
};

export const getMarketplaceNameById = (id) => {
  for (let code in MARKETPLACE_ID_LIST) {
    if (MARKETPLACE_ID_LIST[code] === id) {
      return MARKETPLACE_NAME_LIST[code];
    }
  }

  throw "name not found";
};
